import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';

const uploadService = () => {
  const getUploadUrl = async (
    category: string,
    type: string,
    contentType: string,
  ) => {
    if (type === 'pdf') {
      type = 'file';
    }
    let url;
    let convert;
    if (
      (category === 'exercises' ||
        category === 'advice' ||
        category === 'evaluation' ||
        category === 'treatment' ||
        category === 'treatments' ||
        category === 'condition') &&
      type === 'video'
    ) {
      url = `/upload/${type}/${category}`;
    } else {
      url = `/${type}/${category}`;
    }

    return ChiroUpAPI.post('api', url, {
      body: {
        contentType,
        convert,
      },
    });
  };

  const uploadFile = async (
    file: any,
    payload: { url: string; fields: any },
  ) => {
    const form: FormData = new FormData();

    for (const field in payload.fields) {
      form.append(field, payload.fields[field]);
    }
    form.append('file', file);
    form.append('ContentType', file.type);

    const resp = await fetch(payload.url, {
      method: 'POST',
      body: form,
    });

    return resp;
  };

  const upload = async (
    category: string,
    type: string,
    file: any,
  ): Promise<any> => {
    const uploadUrlRes = await getUploadUrl(category, type, file.type);
    const uploadData = Array.isArray(uploadUrlRes)
      ? uploadUrlRes[0]
      : uploadUrlRes;
    const futureKey = uploadData.futureKey;
    const key = uploadData.key;
    const urlFirstPart = uploadData.url.url;
    const uploadUrl = `${urlFirstPart}/${key}`;
    await uploadFile(file, uploadData.url);
    return futureKey || uploadUrl;
  };

  const status = (file: string) => {
    return ChiroUpAPI.post('api', `/upload/status`, {
      body: {
        file,
      },
    });
  };

  return {
    upload,
    status,
  };
};

export default uploadService();
